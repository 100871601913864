/*
 * Newsletter Messages
 *
 * This contains all the text for the Newsletter component.
 */

import { defineMessages } from 'react-intl';

export const newsletterScope = 'app.containers.newsletterScope';

export const newsletterMessages = {
  title: {
    id: `${newsletterScope}.title`,
    defaultMessage:
      "<span class='highlight'>Le Train-Train d’Alex</span>, #1 des newsletters pour vos voyages terrestres.",
    en: " <span class='highlight'>Le Train-Train d’Alex</span>, #1 newsletter for your land travels.",
    es: " <span class='highlight'>Le Train-Train d’Alex</span>, #1 boletín para tus viajes terrestres.",
    de: " <span class='highlight'>Le Train-Train d’Alex</span>, #1 Newsletter für Ihre Landreisen.",
    it: " <span class='highlight'>Le Train-Train d’Alex</span>, #1 newsletter per i tuoi viaggi terrestri.",
    nl: " <span class='highlight'>Le Train-Train d’Alex</span>, #1 nieuwsbrief voor uw landreizen.",
  },
  description: {
    id: `${newsletterScope}.description`,
    defaultMessage: 'Des infos (très) pratiques pour vos voyages.',
    en: 'Useful (very) practical information for your travels.',
    es: 'Información útil (muy) práctica para tus viajes.',
    de: 'Nützliche (sehr) praktische Informationen für Ihre Reisen.',
    it: 'Informazioni utili (molto) pratiche per i tuoi viaggi.',
    nl: 'Handige (zeer) praktische informatie voor uw reizen.',
  },
  newsletterPlaceholder: {
    id: `${newsletterScope}.newsletterPlaceholder`,
    defaultMessage: 'jonsnow@targaryen.com',
    en: 'jonsnow@targaryen.com',
    es: 'jonsnow@targaryen.com',
    it: 'jonsnow@targaryen.com',
    de: 'jonsnow@targaryen.com',
    nl: 'jonsnow@targaryen.com',
  },
  newsletterSubmit: {
    id: `${newsletterScope}.newsletterSubmit`,
    defaultMessage: "S'inscrire",
    en: 'Subscribe',
    es: 'Inscríbete',
    it: 'Iscriviti',
    de: 'Anmelden',
    nl: 'Aanmelden',
  },
  newsletterThanks: {
    id: `${newsletterScope}.newsletterThanks`,
    defaultMessage: 'Merci ',
    en: 'Thanks ',
    es: 'Gracias ',
    it: 'Grazie ',
    de: 'Danke ',
    nl: 'Dank u ',
  },
  newsletterSuccess: {
    id: `${newsletterScope}.newsletterSuccess`,
    defaultMessage:
      ' Vous êtes maintenant inscrit à notre newsletter, consultez votre boite mail ! :)',
    en: ' You have signed up to our newsletter, check your mailbox ! :)',
    es: ' Ya está suscrito a nuestro boletín de noticias, ¡revise su buzón! :)',
    it: ' Ora sei iscritto alla nostra newsletter, controlla la tua casella di posta! :)',
    de: ' Sie haben sich jetzt für unseren Newsletter angemeldet, schauen Sie in Ihre Mailbox! :)',
    nl: ' U bent nu geabonneerd op onze nieuwsbrief, check uw mailbox! :)',
  },
  newsletterError: {
    id: `${newsletterScope}.newsletterError`,
    defaultMessage:
      "Wooops, la newsletter n'est pas disponible actuellement ! Veuillez réessayer plus tard. :)",
    en: 'You have signed up to our newsletter, check your mailbox ! :)',
    es: 'Wooops, ¡el boletín no está disponible actualmente! Por favor, inténtelo más tarde. :)',
    it: 'Wooops, la newsletter non è attualmente disponibile! Riprovare più tardi. :)',
    de: 'Wooops, der Newsletter ist derzeit nicht verfügbar! Bitte versuchen Sie es später noch einmal. :)',
    nl: 'Wooops, de nieuwsbrief is momenteel niet beschikbaar! Probeer het later nog eens. :)',
  },
};

export default defineMessages(newsletterMessages);
